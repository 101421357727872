<template>
  <div class="cart_wrap">
    <div class="top">
      <span>我的购物车</span>
      <i @click="handleClose" class="el-icon-close"></i>
    </div>
    <div class="content">
      <div class="cart_item" v-for="item in list.list" :key="item.id">
        <div class="img">
          <img :src="item.logo" alt="" />
        </div>
        <div class="flex_1">
          <div class="right">
            <p class="p1 shenglue_1">{{ item.title }}</p>
            <p class="shenglue_1">{{ item.sku }}</p>
            <p class="shenglue_1">{{ item.scqy }}</p>
          </div>
          <div class="flex_sp_c" style="width: 100%">
            <p class="price">
              <span>{{ item.price }}</span> x{{ item.number }}
            </p>
            <p class="del" @click="handleDelete(item.id)">删除</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        <p>
          共 <span>{{ list.list.length }}</span> 件商品
        </p>
        <!-- <p>
          共计: <span>￥{{ total }}</span>
        </p> -->
      </div>
      <div class="btn" @click="handleCart">去购物车结算</div>
    </div>
  </div>
</template>

<script>
import { handleData } from "@/utils";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["list", "total"]),
  },
  watch: {
    list: {
      handler(newVal, oldVal) {
        console.log("监听购物车变化-----", oldVal);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    handleCart() {
      this.$router.push("/index/cart");
      this.handleClose();
    },
    async handleDelete(id) {
      // let p = [{ id }];
      await handleData("cart.delCart", { id: id }, "删除数据");
      this.$emit("getList");
      this.$store.dispatch("getBadge");
    },
  },
};
</script>

<style lang="less" scoped>
.cart_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    i {
      font-size: 18px;
      cursor: pointer;
    }
  }
  .content {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      color: transparent;
    }
    .cart_item {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      background: #ffffff;
      padding: 13px 15px;
      border-bottom: 1px solid #eeeeee;
    }
    .img {
      width: 67px;
      height: 67px;
      background: #eee;
      border-radius: 2px;
      margin-right: 10px;
    }
    .right {
      flex: 1;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 1.9;
      .p1 {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    .price {
      font-size: 12px;
      color: #666;
      span {
        font-size: 16px;
        font-weight: bold;
        color: #f93232;
      }
    }
    .del {
      color: @themeColor;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .bottom {
    padding: 8px;
    padding-top: 12px;
    height: 54px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    span {
      color: #f93232;
      font-weight: bold;
    }
    .btn {
      width: 100px;
      height: 38px;
      background: #f93232;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
