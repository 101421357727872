<template>
  <div class="foot_wrap">
    <div class="top">
      <span>我的足迹</span>
      <i @click="handleClose" class="el-icon-close"></i>
    </div>
    <div class="conten">
      <div class="coll_item">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="img">
            <img :src="item.logo" alt="" />
          </div>
          <p class="title shenglue_1">{{ item.title }}</p>
          <p class="price shenglue_1">￥{{ item.price }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="bottom">
      <div class="btn" @click="goto">查看更多足迹</div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$api("goods.getGoods", { discover_type: 102 }).then((res) => {
      this.list = res.data.list;
    });
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    goto() {
      //   if (this.$route.path == "/index/subRouter/collect") return;
      //   this.$router.push("/index/subRouter/collect");
      //   this.handleClose();
    },
  },
};
</script>

<style lang="less" scoped>
.foot_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    i {
      font-size: 18px;
      cursor: pointer;
    }
  }
  .conten {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    padding: 10px 15px 0 15px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      color: transparent;
    }
    .coll_item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 112px;
        height: 170px;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 10px;
        .img {
          width: 100%;
          height: 83px;
          background-color: #eee;
          margin-top: 15px;
        }
        p {
          padding: 0 10px;
          line-height: 1.5;
        }
        .title {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
        .price {
          font-size: 16px;
          font-weight: 400;
          color: #f93232;
        }
      }
    }
  }
  .bottom {
    padding: 8px;
    padding-top: 12px;
    height: 54px;
    display: flex;
    justify-content: center;
    .btn {
      width: 100px;
      height: 38px;
      background: #f93232;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
