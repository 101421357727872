<template>
  <div class="cate_container">
    <div class="content">
      <div class="cate_wrap">
        <div class="left" @click="handleCate">
          <div class="img">
            <img src="@/assets/img/index/cate.png" alt="" />
          </div>
          <span>商品分类</span>
          <div class="cate_wrap_goods" v-if="showcate">
            <goodsCate @change="changeFlag" />
          </div>
        </div>
        <div class="right">
          <!-- currentPath == item.url.url || activeTab == index  :key="item.pcJump.value" -->
          <div class="item" v-for="(item, index) in cateList" :key="index" @click="handleCom(item, index)" :class="[activeTab == index ? 'active' : '']">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goodsCate from "@/views/home/components/banner/goodsCate.vue";
import { debounce } from "@/utils/tool.js";
import { handleJump } from "@/utils";
import { LsetItem, LgetItem } from "@/utils/storage";
// import { mapGetters } from "vuex";
export default {
  props: ["headerMenu"],
  components: {
    goodsCate,
  },
  // computed: {
  //   ...mapGetters(["goodsListCate"]),
  // },
  data() {
    return {
      currentPath: "",
      // activeTab: -1,
      activeTab: this.$store.state.tabIndex,
      cateList: [],
      // showcate: false,
      showcate: this.$store.state.goodsListCate,
    };
  },
  watch: {
    $route(to) {
      let menuList = this.$store.state.menuData;
      let ind = menuList.findIndex((item) => item.pcJump.value == to.fullPath);
      if (ind >= 0) {
        this.$store.commit("CHANGE_TAB", ind);
        LsetItem("active_tab", ind);
      } else {
        this.$store.commit("CHANGE_TAB", -1);
        LsetItem("active_tab", -1);
      }
      this.currentPath = to.path;
      this.activeTab = this.$store.state.tabIndex;
      this.activeTab = LgetItem("active_tab");
    },
    // goodsListCate(newVal) {
    //   this.showcate = newVal;
    // },
    headerMenu: {
      handler(newVal) {
        // console.log(oldVal);
        const data = newVal;
        this.cateList = data.areas;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    // console.log("接收的参数---", this.headerMenu);
    this.currentPath = this.$route.path;
    // if (this.currentPath == "/index/home" || this.currentPath == "/index/allGoods") {
    //   this.showcate = false;
    // }
    this.showcate = false;
    // this.$store.commit("setGoodsListCate", false);
    this.activeTab = LgetItem("active_tab");
  },
  mounted() {
    window.addEventListener("scroll", debounce(this.handleScroll)); // 监听页面滚动
  },
  methods: {
    handleCom(item, ind) {
      this.$store.commit("setCloseKWlist", false);
      this.$store.commit("setGoodsListCate", false);
      // console.log("tab点击----", item);
      // console.log("当前页面----", this.$route.fullPath);
      if (item.pcJump.value == this.$route.path) {
        // console.log("----------------------------------666");
        history.pushState({}, "", window.location.pathname);
        window.location.reload();
        this.$store.commit("CHANGE_TAB", ind);
        // this.$route.push({
        //   path: "/index/allGoods",
        //   query: {},
        // });
        LsetItem("active_tab", ind);
      } else {
        this.$store.commit("CHANGE_PAGENAME", [item.title]);
        this.$store.commit("CHANGE_TAB", ind);
        LsetItem("active_tab", ind);
        handleJump(item.pcJump);
        this.currentPath = item.pcJump.value;
      }
      // else {

      // }
      // this.$store.commit("SAVE_SEARCHDATA", );
    },
    handleScroll() {
      let top = document.querySelector(".cate_container").getBoundingClientRect().top;
      if (top <= 0) {
        this.$emit("scollTop", true);
      } else {
        this.$emit("scollTop", false);
      }
    },
    handleCate() {
      if (this.currentPath !== "/index/home") {
        this.showcate = !this.showcate;
        // this.$store.commit("setGoodsListCate", this.showcate);
      }
    },
    // changeFlag(bool) {
    //   console.log("父组件接收子组件的数据----", bool);
    //   // console.log("父组件接收子组件的数据类型----", typeof bool);
    //   // this.showcate = bool;
    // },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.cate_container {
  height: 38px;
  background-color: #fff;
  border-bottom: 1px solid @themeColor;
  .cate_wrap {
    display: flex;
    .left {
      width: 200px;
      height: 38px;
      background: @themeColor;
      padding: 0 14px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      line-height: 16px;
      cursor: pointer;
      position: relative;
      .cate_wrap_goods {
        position: absolute;
        top: 38px;
        left: 0;
      }
      .img {
        width: 22px;
        height: 16px;
        margin-right: 12px;
      }
    }
    .right {
      width: calc(1200px - 190px - 200px);
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item {
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        color: #333333;
        &.active {
          font-weight: bold;
          color: @themeColor;
        }
        &:hover {
          color: @themeColor;
        }
      }
    }
  }
}
</style>
