<template>
  <div class="sidebar_wrap" :class="{ 'sidebar-change': changeSidebar }">
    <div class="left">
      <el-tooltip effect="dark" content="会员中心" placement="left">
        <div class="item item1" @click="gotoCenter">
          <div class="img_box">
            <img src="@/assets/img/sidder/hy.png" />
          </div>
        </div>
      </el-tooltip>
      <el-tooltip effect="dark" content="购物车" placement="left">
        <div class="cart_wrap" :class="componentName == 'cart' ? 'active' : ''" @click="sidderClick('cart')">
          <div class="amount">{{ num }}</div>
          <div class="item" style="margin: 0">
            <div class="img_box1">
              <img src="@/assets/img/sidder/cart.png" />
            </div>
          </div>
          <p class="str">购物车</p>
        </div>
      </el-tooltip>
      <el-tooltip effect="dark" content="我的资产" placement="left">
        <div class="item" :class="componentName == 'capital' ? 'active' : ''" @click="sidderClick('capital')">
          <div class="img_box1">
            <img src="@/assets/img/sidder/money.png" />
          </div>
        </div>
      </el-tooltip>
      <el-tooltip effect="dark" content="我的订单" placement="left">
        <div class="item" :class="componentName == 'order' ? 'active' : ''" @click="sidderClick('order')">
          <div class="img_box1">
            <img src="@/assets/img/sidder/order.png" />
          </div>
        </div>
      </el-tooltip>
      <el-tooltip effect="dark" content="我的优惠券" placement="left">
        <div class="item" :class="componentName == 'coupon' ? 'active' : ''" @click="sidderClick('coupon')">
          <div class="img_box1">
            <img src="@/assets/img/sidder/yhq.png" />
          </div>
        </div>
      </el-tooltip>
      <el-tooltip effect="dark" content="我的收藏" placement="left">
        <div class="item" :class="componentName == 'collect' ? 'active' : ''" @click="sidderClick('collect')">
          <div class="img_box1">
            <img src="@/assets/img/sidder/sc.png" />
          </div>
        </div>
      </el-tooltip>
      <el-tooltip effect="dark" content="我的足迹" placement="left">
        <div class="item" :class="componentName == 'footprint' ? 'active' : ''" @click="sidderClick('footprint')">
          <div class="img_box1">
            <img src="@/assets/img/sidder/ll.png" />
          </div>
        </div>
      </el-tooltip>
      <el-tooltip effect="dark" content="返回顶部" placement="left">
        <div class="item" @click.stop="handleBacktop">
          <div class="img_box1">
            <img src="@/assets/img/sidder/top.png" />
          </div>
        </div>
      </el-tooltip>
    </div>
    <div class="right" :class="{ changeWidth: changeSidebar }">
      <!-- <component :is="componentName" @handleClose="handleClose" /> -->
      <cart v-if="componentName == 'cart'" @handleClose="handleClose" @getList="getCartData"></cart>
      <collect v-if="componentName == 'collect'" @handleClose="handleClose"></collect>
      <coupon v-if="componentName == 'coupon'" @handleClose="handleClose"></coupon>
      <capital :userData="userInfo" v-if="componentName == 'capital'" @handleClose="handleClose"></capital>
      <order :listData="list" v-if="componentName == 'order'" @handleClose="handleClose"></order>
      <footprint v-if="componentName == 'footprint'" @handleClose="handleClose"></footprint>
    </div>
  </div>
</template>

<script>
import cart from "./cart.vue";
import collect from "./collect.vue";
import coupon from "./coupon.vue";
import capital from "./capital.vue";
import order from "./order.vue";
import footprint from "./footprint.vue";

import { mapGetters } from "vuex";
import { backToLogin } from "@/utils";
export default {
  components: {
    cart,
    collect,
    coupon,
    capital,
    order,
    footprint,
  },
  data() {
    return {
      changeSidebar: false,
      componentName: "",
      list: [],
      userInfo: {},
    };
  },
  computed: {
    ...mapGetters(["num"]),
  },
  methods: {
    async gotoCenter() {
      await backToLogin();
      this.changeSidebar = false;
      this.componentName = "";
      if (this.$route.path == "/index/subRouter/homepage") return;
      this.$router.push("/index/subRouter/homepage");
    },
    getCapitalData() {
      this.$api("account.getMyInfo").then((res) => {
        this.userInfo = res.data;
      });
    },
    getCartData() {
      this.$api("cart.getCartList").then((res) => {
        this.$store.commit("SAVE_CART", res.data);
        // commit("SAVE_CART", res);
      });
    },
    getOrderData() {
      this.$api("account.getOrder", { page: 1, page_size: 10, status: 0 }).then((res) => {
        this.list = res.data.list;
      });
    },
    async sidderClick(str) {
      console.log("str----", str);
      await backToLogin();
      if (this.componentName == "") {
        this.changeSidebar = true;
      }
      if (this.componentName == str) {
        this.changeSidebar = !this.changeSidebar;
      }
      this.componentName = str;
      if (this.componentName == "cart") {
        this.getCartData();
      }
      if (this.componentName == "order") {
        this.getOrderData();
      }
      if (this.componentName == "capital") {
        this.getCapitalData();
      }

      if (!this.changeSidebar) {
        setTimeout(() => {
          this.componentName = "";
        }, 500);
      }
    },
    handleClose() {
      this.changeSidebar = false;
      setTimeout(() => {
        this.componentName = "";
      }, 500);
    },
    handleBacktop() {
      document.getElementById("index").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar_wrap {
  z-index: 999;
  width: 35px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  transition: all 0.6s ease 0s;
  &.sidebar-change {
    width: 305px;
  }

  .left {
    width: 40px;
    height: 100%;
    background-color: #5c5c5c;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 17px;
      cursor: pointer;
      &:hover {
        background: #222222;
      }
      &.active {
        background: #222222;
      }
    }
    .amount {
      display: inline-flex;
      height: 20px;
      padding: 0 6px;
      margin-left: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
      background: #e91e10;
      border-radius: 100px;
      margin-bottom: -8px;
    }
    .item1 {
      margin-top: 151px;
    }
    .cart_wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: #222222;
      }
      &.active {
        background: #222222;
      }
    }
    .str {
      font-size: 14px;
      font-weight: 400;
      color: #dddddd;
      writing-mode: vertical-rl;
      margin-bottom: 17px;
    }
    .bottom {
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
    .img_box {
      width: 26px;
      height: 26px;
    }
    .img_box1 {
      width: 23px;
      height: 23px;
    }
  }
  .right {
    width: 0;
    height: 100%;
    transition: width 0.6s ease 0s;
    background-color: #f3f3f3;
    overflow: hidden;
    &.changeWidth {
      width: 280px;
    }
  }
}
</style>
