<template>
  <div class="top_container">
    <!-- <img class="top_img" :src="topadv.image && topadv.image.url" @click="click(topadv)" /> -->
    <img class="top_img" v-if="advImg" :src="advImg" @click="click" />
    <div class="content">
      <div class="left">
        <div class="app">
          <span>欢迎使用{{ webTitle }}!</span>
        </div>
        <div class="flex_row" v-if="!isLogin">
          <div class="login_box" @click="$router.push('/login')">请登录</div>
          <div class="login_box zhuce" @click="$router.push('/register')">免费注册</div>
        </div>
        <div v-else class="ml_20 flex_c_c">
          {{ name }}：您好！
          <div class="login_box" style="margin-left: 8px" @click="handleLogOut">安全退出</div>
        </div>
      </div>
      <div class="right">
        <!-- <div class="item" @click="downwn">添加网址到桌面</div>
        <div class="item" @click="hanldeGo('/index/subRouter/order')">我的订单</div>
        <div class="item" @click="hanldeGo('/index/subRouter/homepage')">会员中心</div>
        <div class="item" @click="hanldeGo('/index/subRouter/einvoice')">电子发票</div>
        <div class="item"><a class="item" href="https://www.51yywd.com/login?redirect=%2F" target="_blank">质检报告查询</a></div> -->
        <div class="item" v-for="item in headerMenu.toplink" :key="item.id" :style="{ color: `${item.titlecolor}` }" @click="jumpFn(item)">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump, backToLogin } from "@/utils";
import { LgetItem, LsetItem } from "@/utils/storage";
import { mapGetters } from "vuex";
export default {
  props: ["headerMenu"],
  data() {
    return {
      settingImg: {},
      advImg: "",
      headData: {},
      topadv: [],
      webTitle: "",
      isLogin: false,
      userInfoData: {
        organization_name: "",
      },
    };
  },
  computed: {
    name() {
      if (LgetItem("info")) {
        // console.log("登录账号111-----", LgetItem("info"));
        return LgetItem("info").organization_name;
      } else {
        return "游客";
      }
    },
    ...mapGetters(["userInfo"]),
  },
  created() {
    // console.log("vuex-----", this.userInfo);
    // let infoStr = localStorage.getItem("user_info");
    // this.userInfoData = JSON.parse(infoStr);
    // let a = LgetItem("info");
    // console.log("顶部信息000------", this.headerMenu);
    this.handleisLogin();
    this.webTitle = this.$store.state.settingData.title;
  },
  watch: {
    headerMenu: {
      handler(newVal) {
        // console.log(oldVal);
        this.headData = newVal;
        this.topadv = this.headData.topadv;
        this.webTitle = this.$store.state.settingData.title;
        if (this.topadv && this.topadv.length > 0) {
          this.advImg = this.topadv[0].logo;
        }
      },
      immediate: true,
      deep: true,
    },
    // $route(to) {
    //   console.log(to);
    //   if (LgetItem("info")) {
    //     console.log("登录账号-----", LgetItem("info"));
    //     this.name = LgetItem("info").organization_name;
    //   } else {
    //     this.name = "游客";
    //   }
    // },
  },
  mounted() {
    // this.$bus.$on("logout", this.handleisLogin);
  },

  methods: {
    handleisLogin() {
      if (LgetItem("token")) this.isLogin = true;
      else this.isLogin = false;
      // if (localStorage.getItem("token")) {
      //   this.isLogin = true;
      // } else {
      //   this.isLogin = false;
      // }
      // console.log("是否登录-----", this.isLogin);
      // console.log("登陆账号-----", LgetItem("info").organization_name);
    },
    async hanldeGo(path) {
      this.$store.commit("setCloseKWlist", false);
      this.$store.commit("CHANGE_TAB", -1);
      await backToLogin();
      if (this.$route.path == path) return;
      this.$router.push(path);
    },
    toLive() {
      if (this.isLogin) {
        const { href } = this.$router.resolve({
          path: "/live",
        });
        window.open(href, "_blank");
      } else {
        this.$message.warning("进入直播间请先登录！");
      }
    },
    click() {
      if (this.topadv.length > 0) {
        // this.advImg = this.topadv[0].logo;
        handleJump(this.topadv[0].pcJump);
      }
      // handleJump(item.pcJump);
      // this.$api("home.getClick", { adv_id: item.id });
    },
    // add() {
    //   this.$api("home.toDesk").then((res) => {
    //     console.log(res);
    //   });
    // },
    urlDownload(content, fileName) {
      // 方法一
      // const eleLink = document.createElement("a");
      // eleLink.download = fileName;
      // eleLink.style.display = "none";
      // const blob = new Blob([content]);
      // eleLink.href = URL.createObjectURL(blob);
      // document.body.appendChild(eleLink);
      // eleLink.click();
      // document.body.removeChild(eleLink);
      // 方法二
      var a = document.createElement("a"); // 创建一个<a></a>标签
      a.href = content; //重点（如测试发现下载文件不存在/找不到，检查路径）
      a.download = fileName; // 设置下载文件文件名
      a.style.display = "none"; // 隐藏a标签
      document.body.appendChild(a); // 将a标签追加到文档对象中
      a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove();
    },
    downwn() {
      // const { protocol, href, hostname } = location;
      // const content = `URL=https://pc.web.wiscode.cn
      // IconFile=http://pc.web.wiscode.cn/favicon.ico
      // IconIndex=0
      // HotKey=1613
      // IDList=
      // [{000214A0-0000-0000-C000-000000000046}]
      // Prop3=19,2`;
      // this.urlDownload(content, "智联医药.url");
      this.$api("home.toDesk").then((res) => {
        if (res.code == 200) {
          this.urlDownload(res.data, "四川江药.url");
        }
      });
    },
    handleLogOut() {
      this.$confirm("是否确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          LsetItem("token", "");
          LsetItem("info", "");
          this.$store.dispatch("delUserInfo");
          localStorage.setItem("user_info", "");
          LsetItem("canchange", false);
          this.$router.replace("/login");
        })
        .catch(() => {});
    },
    jumpFn(data) {
      let jump = data.pcJump;
      let jumpValue = data.pcJump.value;
      if (jumpValue) {
        if (jumpValue == "/index/goodsDetail") {
          //商品详情页打开新标签
          let routeUrl = this.$router.resolve({
            path: jumpValue,
            query: { value: jump.options },
          });
          window.open(routeUrl.href, "_blank");
        } else {
          let preStr = jumpValue.substr(jumpValue.length - 4, jumpValue.length - 1);
          if (preStr == ".url") {
            this.urlDownload(jumpValue, "四川江药.url");
          } else {
            if (jump.options) {
              this.$router.push({
                path: jumpValue,
                query: { value: jump.options },
              });
            } else {
              this.$router.push({
                path: jumpValue,
              });
            }
          }
        }
      }
    },
  },
  destroyed() {
    this.$bus.$off("logout", this.handleisLogin);
  },
};
</script>

<style lang="less" scoped>
.top_container {
  font-size: 12px;
  font-family: Microsoft YaHei;
  background-color: #f8f8f8;
  .top_img {
    width: 100%;
    height: 80px !important;
  }
  .content {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 100%;
    .left {
      display: flex;
      .app {
        // margin-top: 2px;
        border-right: 1px solid #cccccc;
        padding-right: 15px;
        display: flex;
        span {
          line-height: 1.5;
        }
      }
      .login_box {
        cursor: pointer;
        width: 65px;
        // line-height: 100%;
        height: 20px;
        background: @themeColor;
        border-radius: 2px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
      }
      .zhuce {
        background: #f1622f;
      }
    }
    .right {
      display: flex;
      .item {
        text-decoration: none;
        color: #515a6e;
        cursor: pointer;
        padding: 0 10px;
        border-right: 1px solid #cccccc;
        &:last-child {
          border: none;
          padding-right: 0;
        }
        &:hover {
          color: @themeColor;
        }
      }
      .wx_item {
        margin-left: 10px;
      }
      .green {
        color: @themeColor;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.code_img {
  width: 130px;
  height: 130px;
  background-color: #ccc;
  margin-right: 15px;
  img {
    width: 130px;
    height: 130px;
  }
}
.str {
  font-size: 12px;
  line-height: 2.4;
}
.apple {
  width: 132px;
  height: 30px;
  background: #ff6766;
  box-shadow: 0px 0px 4px 0px rgba(255, 103, 102, 0.3);
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  padding-left: 22px;
  img {
    margin-right: 10px;
  }
}
.android {
  width: 132px;
  height: 30px;
  background: #26d871;
  box-shadow: 0px 0px 4px 0px rgba(38, 216, 113, 0.3);
  border-radius: 4px;
  color: #fff;
  margin-top: 7px;
  padding-left: 22px;

  img {
    margin-right: 10px;
  }
}
</style>
