<template>
  <div class="foot_container">
    <div class="intro">
      <div class="intro_inner flex_ar_c">
        <div class="intro_inner_box flex_row_aic">
          <div><img src="@/assets/img/home/home_by.png" alt="" /></div>
          <p class="f18 c198">满额包邮</p>
        </div>
        <div class="intro_inner_box flex_row_aic">
          <div><img src="@/assets/img/home/home_crd.png" alt="" /></div>
          <p class="f18 c198">最快次日达</p>
        </div>
        <div class="intro_inner_box flex_row_aic">
          <div><img src="@/assets/img/home/home_pzbz.png" alt="" /></div>
          <p class="f18 c198">品质保证</p>
        </div>
        <div class="intro_inner_box flex_row_aic">
          <div><img src="@/assets/img/home/home_shwy.png" alt="" /></div>
          <p class="f18 c198">售后无忧</p>
        </div>
      </div>
    </div>
    <div class="top">
      <div class="top_inner">
        <div class="item_box">
          <div class="bottom_left">
            <div class="img_logo"><img src="https://static.wiscode.cn/uploadfiles/20240913/17261953578687.png" alt=""></div>
            <div class="img_app"><img src="https://www.scjzyt.com/static/images/apper.jpg" alt=""></div>
            <div class="f14 app">江药四川APP</div>
          </div>
          <div class="item" v-for="(item, index) in helpData" :key="index">
            <!-- <div class="img">
              <img :src="item.icon" alt="" />
            </div> -->
            <div>
              <p class="til">{{ item.title }}</p>
              <p v-for="sub in item.list" :key="sub.id" class="c_p" style="line-height: 36px" @click="handleDetail(sub, item.id)">{{ sub.title }}</p>
            </div>
          </div>
          <div class="bottom_right">
            <div class="lxdh">183-2836-3094</div>
            <div class="times f14">投诉电话：周一至周五 8:30-18:00</div>
            <div class="imgbox">
              <div class="img_gzh"><img src="https://ytpub.oss-cn-shenzhen.aliyuncs.com/newPc/jysc_gzh.jpg" alt=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_inner">
        <div class="info_item">
          <div v-for="item in headerMenuData.license" :key="item.id">
            <span>{{ item.title }}：</span>
            <span class="cont" @click="click(item)">{{ item.zjhm }}</span>
          </div>
        </div>
        <p>{{ bottom.str1 }}</p>
        <div class="flex_sp">
          <p>{{ bottom.str2 }}</p>
          <p>公司地址：{{ bottom.address }}</p>
        </div>
        <div class="flex_sp">
          <p>{{ bottom.str3 }}</p>
          <div v-html="bottom.support"></div>
        </div>
      </div>
    </div>

    <div class="popup" v-if="dialogVisible && img" @click="endDialog">
      <div class="po">
        <el-button icon="el-icon-close" circle @click="endDialog"></el-button>
        <el-image :src="img" @load="loadImage" @click="handleJump"> </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { loadImage } from "@/utils";
export default {
  props: ["headerMenu", "helpData"],
  data() {
    return {
      // headerMenu: this.$store.state.homeComData,
      // helpData: this.$store.state.homeData.help,
      list: [],
      arr: [],
      info: {},
      dialogVisible: false,
      img: "",
      imgarr: {},
      loadImage,
      bottomData: {},
      headerMenuData: {},
      bottom: {
        str1: "",
        str2: "",
        str3: "",
        address: "",
        support: "",
      },
    };
  },
  watch: {
    headerMenu: {
      handler(newVal) {
        // console.log(oldVal);
        this.headerMenuData = newVal;
        // this.bottom = this.headerMenuData.bottom;
        // console.log("底部数据----", this.bottom);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    // console.log("获取store的help数据---", this.$store.state.homeData.help);
    this.bottomData = localStorage.getItem("bottomData");
    // console.log("获取底部数据---", JSON.parse(this.bottomData));
    this.bottom = JSON.parse(this.bottomData).bottom;
  },
  methods: {
    click(item) {
      // let imgFlag = /\.(jpg|jpeg|png|gif|webp)$/i.test(item.logo);
      // console.log("是否是图片----", imgFlag);
      if (item.logo) {
        this.img = item.logo;
        this.dialogVisible = true;
      } else if (item.url) {
        // url = item.url;
        window.open(item.url, "_blank");
      }

      // let url = "";
      // this.imgarr = {};
      // if (item.image) {
      //   this.imgarr = item;
      //   this.dialogVisible = true;
      // } else if (item.url) {
      //   url = item.url;
      //   window.open(url, "_blank");
      // }

      // let a = window.open("");
      // a.document.write(`<!DOCTYPE html><html><body ><img src='${url}'/></body></html>`);
    },

    handleJump() {
      if (this.imgarr.url) {
        window.open(this.imgarr.url, "_blank");
      }

      this.imgarr = {};
      this.dialogVisible = false;
    },

    endDialog() {
      this.imgarr = {};
      this.dialogVisible = false;
    },

    handleDetail(item, fid) {
      console.log("帮助分类---", item, fid);
      // if (this.$route.path == "/index/news") {
      //   this.$bus.$emit("updateNews", { id: item.id });
      //   return;
      // }
      this.$router.push({
        // name: "News",
        path: "/index/news",
        query: { type: "help", id: item.id, fid: fid, name: item.title },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.foot_container {
  min-height: 410px;
  .intro{
    background-color: #fff;
    .intro_inner{
      width: 1200px;
      margin: 0 auto;
      padding: 15px 0;
      border-bottom:1px solid #e5e5e5;
      .intro_inner_box{
        >div{
          width: 50px;
          height: 50px;
          margin: 0 10px;
        }
      }
    }
  }
  .top {
    min-height: 200px;
    background-color: #fff;
    .top_inner {
      width: 1200px;
      margin: 0 auto;
      padding-top: 15px;
      padding-bottom: 15px;
      .item_box {
        display: flex;
        justify-content: space-around;
        .bottom_left{
          .img_logo{
            width: 168px;
            height: 48px;
          }
          .img_app{
            width: 110px;
            height: 110px;
            margin: 20px auto 5px;
          }
          .app{
            text-align: center;
          }
        }
        .bottom_right{
          .lxdh{
            font-size: 32px;
            text-align: center;
            color: #2f3235;
            font-weight: bold;
            line-height: 44px;
          }
          .times{
            color: #5f6265;
            line-height: 18px;
            text-align: center;
          }
          .imgbox{
            .img_gzh{
              width: 100px;
              height: 100px;
              margin: 15px auto;
            }
          }
        }
        .item {
          display: flex;
          align-items: flex-start;
          .img {
            width: 60px;
            height: 60px;
            margin-right: 15px;
          }
          .til {
            margin-top: 15px;
            font-size: 20px;
            font-weight: bold;
            color: @themeColor;
            margin-bottom: 15px;
          }
          .name {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            margin-top: 10px;
          }
          .tip1 {
            margin-left: 20px;
          }
        }
      }
    }
  }
  .bottom {
    min-height: 176px;
    background: #2c2f3a;
    padding-top: 16px;
    padding-bottom: 10px;
    box-sizing: border-box;
    color: #fffefe;
    font-size: 13px;
    line-height: 30px;
    .bottom_inner {
      width: 1200px;
      margin: 0 auto;
      .info_item {
        display: flex;
        flex-wrap: wrap;
        .cont {
          margin-right: 15px;
          padding-right: 15px;
          color: #b2b4c4;
          border-right: 1px solid #fffefe;
          cursor: pointer;
        }
      }
    }
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .po {
    position: relative;
    .el-button {
      position: absolute;
      right: -50px;
      top: -50px;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
}
</style>
