<template>
  <div class="coupon_wrap">
    <div class="top">
      <span>我的优惠券</span>
      <i @click="handleClose" class="el-icon-close"></i>
    </div>
    <div class="conten">
      <div class="cou_item" v-for="item in list" :key="item.id">
        <img src="@/assets/img/zone/yhq.png" alt="" />
        <div class="box">
          <p class="pr">
            ￥<span>{{ Number(item.money).toFixed(0) }}</span>
          </p>
          <div class="flex_1">
            <div class="p1">满{{ item.use_money }}可用</div>
            <div>{{ item.date_range }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$api("account.getCoupon", { status: 101 }).then((res) => {
      this.list = res.data.list;
      this.list.forEach((e) => {
        let arr = e.date_range.split(" ");

        var reg = /-/g; //o为要替换的关键字，不能加引号，否则替换不生效，i忽略大小写，g表示全局查找。
        // var str1=str.replace(reg,"**")

        e.qssj = arr[0].replace(reg, "/");
        e.jssj = arr[3].replace(reg, "/");
      });
    });
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style lang="less" scoped>
.coupon_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    i {
      font-size: 18px;
      cursor: pointer;
    }
  }
  .conten {
    width: 100%;
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      color: transparent;
    }

    .cou_item {
      width: 236px;
      height: 81px;
      position: relative;
      margin-top: 15px;
      margin-left: 17px;
      img {
        position: absolute;
      }
      .box {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        .pr {
          width: 80px;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          color: #ffffff;
          span {
            font-size: 30px;
          }
        }
        .flex_1 {
          text-align: center;
          color: #ffffff;
          font-size: 11px;
          padding-left: 5px;
          .p1 {
            font-size: 14px;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
</style>
